import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";


import coupleToilet from "../images/plumbing/coupleToilet.png";
import pvcf from "../images/plumbing/pvcFittings.png";
import pipes from "../images/plumbing/pipes.png";
import copperpipes from "../images/plumbing/copperpipes.png";
import solventcement from "../images/plumbing/solventcement.png";
import copperf from "../images/plumbing/copper-pipe-fitting.png";

function PlumbingPage() {
    return (
      <Layout>
        <SEO
          title="Plumbing"
          keywords={[`Plumbing`, `fittings`, `toilet`, `copper`, `Zimbabwe`]}
        />
  
       
            <div class="rounded overflow-hidden">
              <img src={coupleToilet}  alt="Sunset in the mountains"/>
               <div>
               <div class="font-bold text-xl mb-2">Close Couple Toilet Suites</div>
               <p class="text-gray-700 text-base">
               
               </p>
               </div>
               <img src={pvcf}  alt="Sunset in the mountains"/>
               <div>
               <div class="font-bold text-xl mb-2">PVC fittings</div>
               <p class="text-gray-700 text-base">
               
               </p>
             </div>
             
            </div>
          
            <div class="rounded overflow-hidden">
              <img src={pipes}  alt="Sunset in the mountains"/>
               <div class="w-1/2 h-8">
               <div class="font-bold text-xl mb-2">Above/Underground Pipes</div>
               <p class="text-gray-700 text-base">
               
               </p>
             </div>
             <img src={copperpipes}  alt="Sunset in the mountains"/>
               <div class="w-1/2  h-8">
               <div class="font-bold text-xl mb-2">Copper Pipes</div>
               <p class="text-gray-700 text-base">
              
               </p>
             </div>

             


            </div>
           
          
            <div class="rounded overflow-hidden">
              <img src={solventcement}  alt="Sunset in the mountains"/>
               <div class="w-1/3  h-12">
               <div class="font-bold text-xl mb-2">Solvent Cement</div>
               <p class="text-gray-700 text-base">
               
               </p>
               </div>
               <img src={copperf}  alt="Sunset in the mountains"/>
               <div class="w-1/3 h-12">
               <div class="font-bold text-xl mb-2">Copper Fittings</div>
               <p class="text-gray-700 text-base">
               
               </p>
             </div>
             
            </div>

            <h4 className="bg-yellow-400 text-2xl font-bold inline-block my-8 p-3">
          Call or WhatsApp: +263 774 500 868 or +263 773 386 695
        </h4>
            
         
        
      </Layout>
    );
  }
  
  export default PlumbingPage;
  